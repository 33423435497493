/* eslint-disable no-return-assign */
<template>
  <div class="step_container">
    <h1 class="step_title">AUTHORIZED PERSON 授权人</h1>
    <div class="step_question_container">
      <h2 class="step_question_title">AUTHORIZED PERSON 授权人</h2>
      <a-form-model
        layout="horizontal"
        :model="threeForm"
        :rules="rules"
        ref="person_third_form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item
          required
          prop="has_authorize_other"
          label="Do you authorize others to process your foreign exchange? 您是否授权他人处理您的外汇？"
        >
          <a-radio-group
            class="modify_antd_radio_id"
            name="has_authorize_other"
            @change="handleRadioSelect"
            :value="threeForm.has_authorize_other"
          >
            <a-radio :value="true"> Yes 是 </a-radio>
            <a-radio :value="false"> No 否 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <div v-if="has_authorize_other">
          <div class="mutiple_form_layout">
            <a-form-model-item
              prop="authorize_title"
              :style="GetScreenStyle.width30percent"
              required
              label="Title 称谓"
            >
              <a-select
                @change="
                  (value) => {
                    handleSelect(value, 'authorize_title');
                  }
                "
                :value="threeForm.authorize_title"
              >
                <a-select-option value="Mr"> Mr </a-select-option>
                <a-select-option value="Miss"> Miss </a-select-option>
                <a-select-option value="Ms"> Ms </a-select-option>
                <a-select-option value="Other"> Other </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              prop="authorize_first_name"
              required
              label="authorize_first_name 名"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'authorize_first_name');
                  }
                "
                :value="threeForm.authorize_first_name"
              />
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              prop="authorize_last_name"
              required
              label="authorize_last_name 姓"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'authorize_last_name');
                  }
                "
                :value="threeForm.authorize_last_name"
              />
            </a-form-model-item>
          </div>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            prop="authorize_gender"
            label="Gender 性别"
          >
            <a-radio-group
              class="modify_antd_radio_id"
              @change="handleRadioSelect"
              :value="threeForm.authorize_gender"
              name="authorize_gender"
            >
              <a-radio value="male"> Male </a-radio>
              <a-radio value="female"> Female </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <div class="mutiple_form_layout">
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              required
              prop="authorize_date_of_birth"
              label="Date of Birth 出生日期"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'authorize_date_of_birth');
                  }
                "
                :value="threeForm.authorize_date_of_birth"
              />
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              required
              prop="authorize_place_of_birth"
              label="Place of Birth 出生地点"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'authorize_place_of_birth');
                  }
                "
                :value="threeForm.authorize_place_of_birth"
              />
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              required
              prop="authorize_nationality"
              label="Nationality 国籍"
            >
              <a-select
                @change="
                  (value) => {
                    handleSelect(value, 'authorize_nationality');
                  }
                "
                :value="threeForm.authorize_nationality"
              >
                <a-select-opt-group label="Popular Countries">
              <a-select-option v-for='item of popularCountryList' v-bind:key="item.code" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group label="All Countries">
              <a-select-option v-for='item of allCountryList' v-bind:key="item.code" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select-opt-group>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="mutiple_form_layout">
            <a-form-model-item
              :style="GetScreenStyle.width45percent"
              required
              prop="authorize_tax_country"
              label="Country of Tax Residence 税务国家"
            >
              <a-select
                @change="
                  (value) => {
                    handleSelect(value, 'authorize_tax_country');
                  }
                "
                :value="threeForm.authorize_tax_country"
              >
                <a-select-opt-group label="Popular Countries">
              <a-select-option v-for='item of popularCountryList' v-bind:key="item.code" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group label="All Countries">
              <a-select-option v-for='item of allCountryList' v-bind:key="item.code" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select-opt-group>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width45percent"
              label="Relationship 与授权人关系"
              prop="relation_with_authorize"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'relation_with_authorize');
                  }
                "
                :value="threeForm.relation_with_authorize"
              />
            </a-form-model-item>
          </div>
          <a-form-model-item
          prop="authorize_residential_address"
          label="Residential Address(No Po BOX) 居住地址">
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'authorize_residential_address');
                }
              "
              :value="threeForm.authorize_residential_address"
            />
          </a-form-model-item>
          <div class="mutiple_form_layout">
            <a-form-model-item
              prop="authorize_city"
              :style="GetScreenStyle.width30percent"
              required
              label="City 城市"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'authorize_city');
                  }
                "
                :value="threeForm.authorize_city"
              />
            </a-form-model-item>
            <a-form-model-item
              prop="authorize_country"
              :style="GetScreenStyle.width30percent"
              required
              label="Country 国家"
            >
              <a-select
                @change="
                  (value) => {
                    handleSelect(value, 'authorize_country');
                  }
                "
                :value="threeForm.authorize_country"
              >
                <a-select-opt-group label="Popular Countries">
              <a-select-option v-for='item of popularCountryList' v-bind:key="item.code" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group label="All Countries">
              <a-select-option v-for='item of allCountryList' v-bind:key="item.code" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select-opt-group>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              required
              prop="authorize_postcode"
              label="Postcode 邮政编码"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'authorize_postcode');
                  }
                "
                :value="threeForm.authorize_postcode"
              />
            </a-form-model-item>
          </div>
          <div class="mutiple_form_layout">
            <a-form-model-item
              prop="authorize_home_telephone"
              :style="GetScreenStyle.width30percent"
              required
              label="Home Phone 家庭电话"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'authorize_home_telephone');
                  }
                "
                :value="threeForm.authorize_home_telephone"
              />
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              required
              prop="authorize_mobile_phone"
              label="Mobile 移动电话"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'authorize_mobile_phone');
                  }
                "
                :value="threeForm.authorize_mobile_phone"
              />
            </a-form-model-item>
            <a-form-model-item
              prop="authorize_email_address"
              :style="GetScreenStyle.width30percent"
              required
              label="E-mail 电子邮箱"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'authorize_email_address');
                  }
                "
                :value="threeForm.authorize_email_address"
              />
            </a-form-model-item>
          </div>
          <div>
            <div class="step_sub_title">
              Please upload your ID Verification(select one)
              请上传您的ID认证（请选择一个上传）
            </div>
            <a-form-model-item
            :style="GetScreenStyle.width100percent"
            prop="authorize_id_type"
            label=""
          >
            <a-radio-group
              class="modify_antd_radio"
              name="authorize_id_type"
              v-model="threeForm.authorize_id_type"
              @change="handleRadioSelect"
            >
              <a-radio :style="radioStyle" :value="1">
                <ul class="radio_step_ul_style">
              <li class="li_require">
                Passport the copy of the passport photo and signature page, and residence page if the client is a New Zealand resident. 护照（信息页和签字页+新西兰居住签证《如有》）
              </li>
            </ul>
              </a-radio>
              <a-radio :style="radioStyle" :value="2">
                <ul class="radio_step_ul_style">
              <li class="li_require">
                Chinese National ID, (the copy of front and back side 中国身份证正反面)
              </li>
            </ul>
              </a-radio>
              <a-radio :style="radioStyle" :value="3">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                  New Zealand Driver License + New Zealand Bank Cards (the copy of front and back side新西兰驾照正反面+新西兰银行卡正反面)
                </li>
              </ul>
            </a-radio>
            <a-radio :style="radioStyle" :value="4">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                  New Zealand Driver Licence + New Zealand Bank Statement/IRD Statement (the copy of front and back side新西兰驾照正反面 + 新西兰银行流水单/IRD 记录)
                </li>
              </ul>
            </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <div v-if="threeForm.authorize_id_type == 1">
            <!-- face-to-face 1. nz/au passport value=1 -->
                <AUploadFile
            title="授权人New Zealand/Australiad护照信息页+签字页"
            prop="authorize_passport_file_list"
            :fileList="authorize_passport_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="授权人New Zealand/Australia护照 签证页《如有》"
            prop="authorize_passport_visa_file_list"
            :fileList="authorize_passport_visa_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
          <div v-if="threeForm.authorize_id_type == 2">
            <!-- face-to-face 2. nz/au passport value=1 -->
            <AUploadFile
            title="授权人中国身份证正面"
            prop="authorize_cn_identify_file_list"
            :fileList="authorize_cn_identify_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="授权人中国身份证反面"
            prop="authorize_cn_identify_file_list"
            :fileList="authorize_cn_identify_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
          <div v-if="threeForm.authorize_id_type == 3">
            <!-- face-to-face 3. nz/au passport value=3 -->
            <AUploadFile
            title="授权人New Zealand/Australia驾照正面"
            prop="authorize_nz_driver_front_file_list"
            :fileList="authorize_nz_driver_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="授权人New Zealand/Australia驾照反面"
            prop="authorize_nz_driver_back_file_list"
            :fileList="authorize_nz_driver_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="授权人New Zealand/Australia银行卡正面"
            prop="authorize_nz_bank_card_front_file_list"
            :fileList="authorize_nz_bank_card_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="授权人New Zealand/Australia银行卡反面"
            prop="authorize_nz_bank_card_back_file_list"
            :fileList="authorize_nz_bank_card_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
          <div v-if="threeForm.authorize_id_type == 4">
            <!-- face-to-face 4. nz/au passport value=4 -->
            <AUploadFile
            title="授权人New Zealand/Australia驾照正面"
            prop="authorize_nz_driver_front_file_list"
            :fileList="authorize_nz_driver_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="授权人New Zealand/Australia驾照反面"
            prop="authorize_nz_driver_back_file_list"
            :fileList="authorize_nz_driver_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="授权人New Zealand/Australia银行流水单 + Ird记录"
            prop="authorize_nz_bank_statement_ird_file_list"
            :fileList="authorize_nz_bank_statement_ird_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
            <div>
              <div class="ul_main_title">
                1.Bank Statements and Bank Correspondence 银行对账单和银行通信
              </div>
              <ul class="step_ul_style">
                <li>
                  This must be a statement from a bank/IRD that has been posted
                  to the client and is dated within the last 3 months.
                  这必须是银行/
                  IRD的记录，该记录信件必须是最近三个月内通过信件发送给注册人的。
                </li>
                <li>
                  Bank correspondence must confirm a business relationship
                  exists between the bank and the client.
                  银行通信必须是能确认银行与注册人之间存在业务关系的
                </li>
                <li>
                  Printed information from online account or email
                  correspondence is not acceptable unless it is JP/lawyer
                  certified. 除非获得JP
                  /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                </li>
              </ul>
            </div>
            <!-- 2. -->
            <div>
              <div class="ul_main_title">2.Utility Bill 各种账单</div>
              <ul class="step_ul_style">
                <li>
                  This must be a statement for utility service at a fixed
                  location that has been posted to the client and is dated
                  within the last 3 months.
                  这必须最近三个月内通过邮寄发送去注册人的账单。
                </li>
                <li>
                  Fixed services include electricity, water, gas, home
                  internet/landlines and Sky.
                  服务账单包括电力，水，煤气，家庭互联网/固定电话和电视。
                </li>
                <li>
                  Fixed services do not include mobile phones.
                  服务账单不包括手机账单
                </li>
                <li>
                  Printed information from online account or email
                  correspondence is not acceptable unless it is JP/lawyer
                  certified. . 除非获得JP
                  /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                </li>
              </ul>
            </div>
            <!-- 3. -->
            <div>
              <div class="ul_main_title">
                3.Government Agency Correspondence (Council Rates, etc.)
                政府机构通信
              </div>
              <ul class="step_ul_style">
                <li>
                  This must be a statement, letter or invoice that has been
                  posted to the client from a government agency and is dated
                  within the last 3 months.
                  这必须最近三个月内来自政府机构通过邮寄发送去注册人的声明/信件/发票。
                </li>
                <li>
                  This includes confirmation of bond lodgements with the
                  Department of Building and Housing.
                  这可以是来自于建筑和住房部押金投管确认信
                </li>
                <li>
                  Fixed services do not include mobile phones.
                  服务账单不包括手机账单
                </li>
                <li>
                  Printed information from online account or email
                  correspondence is not acceptable unless it is JP/lawyer
                  certified. . 除非获得JP
                  /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                </li>
              </ul>
            </div>
            <!-- 4. -->
            <div>
              <div class="ul_main_title">
                4.Electoral Commission Correspondence 选举委员会函件
              </div>
              <ul class="step_ul_style">
                <li>
                  This must be a letter that has been posted to the client and
                  is dated within the last 3 months.
                  这必须是三个月内通过邮寄发送至注册人的信件
                </li>
                <li>
                  Printed information from online account or email
                  correspondence is not acceptable unless it is JP/lawyer
                  certified. . 除非获得JP
                  /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息
                </li>
              </ul>
            </div>
            <!-- 5. -->
            <div>
              <div class="ul_main_title">
                5.Tenancy Agreement 租赁协议 Correspondence 选举委员会函件
              </div>
              <ul class="step_ul_style">
                <li>
                  This must be a signed tenancy agreement dated within the last
                  3 months. 这必须是过去3个月内签署的租赁协议
                </li>
              </ul>
            </div>
            <!-- 6. -->
            <div>
              <div class="ul_main_title">6.Vehicle Registration 车辆登记</div>
              <ul class="step_ul_style">
                <li>
                  This must be a current vehicle registration that has been
                  posted to the client and is dated within the last 3 months.
                  这必须是三个月内通过邮寄发送给注册人的车辆登记信件
                </li>
                <li>
                  Printed information from online account or email
                  correspondence is not acceptable unless it is JP/lawyer
                  certified. . 除非获得JP
                  /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                </li>
              </ul>
            </div>
            <!-- 地址认证上传 -->
            <div class="step_upload_box marginT_10">
              <a-form-model-item
               prop="authorize_address_prove_file_list"
            >
              <a-upload
                action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                list-type="picture"
                :before-upload="beforeUpload"
                name="userfile"
                @change='(e)=>{handleFileList(e, "authorize_address_prove_file_list")}'
                :file-list="authorize_address_prove_file_list"
              >
                <a-button> <a-icon type="upload" /> upload </a-button>
              </a-upload>
            </a-form-model-item>
              <div class="step_notice_text marginT_10">
                The file's fomat supports JPG,PNG,JPEG,PDF or any video types
                and the size is not exceed 20MB
              </div>
            </div>
            <div>
              <div class="step_sub_title">
              Authorized Person Signature 授权人签名
              <a-form-model-item
              prop="author_signature"
              >
              <Signature
              class="marginX20"
              :outsourceSign='testSign'
              @upLoadSignPic='handleSignUpload'
              ></Signature>
              </a-form-model-item>
            </div>
            <div class="claim_text">
              You can generate a signature link for sending and signing. To confirm the signature after submitting, <b>please refresh this page</b>.     生成签名链接用于发送给客户签字，确认签字提交后请<b>刷新本页面</b>。
            </div>
            <button @click='handleShowGenerate' v-show="get_show_generate_link" class="generate_btn">Generate the Link 生成链接</button>
            <div v-show="!get_show_generate_link" class="general_sign_container">
              <div class="general_sign_title">Copy link to share 复制链接分享</div>
                <div class="general_sign_url">
                  {{getShareLink}}
                </div>
                <button
                :data-clipboard-text="getShareLink" ref="copy_btn" class="copy_link_btn">Copy Link 复制链接</button>
                <span v-if="is_copy_success === true" class="copy_success_text">Successfully copied 已复制成功</span>
            </div>
            </div>
          </div>
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
// import textSign from '../../../../utils/Sign'
import { mapActions, mapGetters, mapState } from 'vuex'
import Signature from '../../../../components/Signature.vue'
import AUploadFile from '../../../../components/AUploadFile'
// import Clipboard from 'clipboard'

export default {

  data () {
    return {
      radioStyle: {
        lineHeight: '30px',
        width: '100%',
        marginBottom: '10px'
      },
      show_generate_link: false,
      is_copy_success: false,
      copyValue: 'hello world',
      activeKey: 0,
      panes: [
        { title: 'Tax Number 税务信息 # 1', content: { other_tax_contry: 'male', other_tax_num: 'other_tax_num' }, key: 0, closable: false }
      ],
      newTabIndex: 0,
      // ori
      residential_country_list: [],
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    }
  },
  created () {
    this.testSign = this.author_signature
  },
  mounted () {
  },
  components: {
    Signature,
    AUploadFile
  },
  methods: {
    handleSignUpload (val) {
      console.log('upload sign')
      this.ChangeFormAction({ content: 'author_signature', value: val })
    },
    handleFileList ({ fileList }, content) {
      if (fileList.length <= 0) {
        this.ChangeObjectAction({ content, value: [] }
        )
        return
      }
      const lastestFile = fileList[fileList.length - 1]
      if (!lastestFile.type) {
        return
      }

      const isValidType = lastestFile.type === 'image/jpeg' || lastestFile.type === 'image/png' || lastestFile.type === 'application/pdf'
      fileList = fileList.slice(-3)
      if (!isValidType) {
        return
      }
      const isLt2M = lastestFile.size / 1024 / 1024 < 20
      if (!isLt2M) {
        return
      }
      const _fileList = fileList.map(item => ({
        ...item,
        url: item.response,
        originFileObj: null
      }))
      const __fileList = _fileList.filter(item => ((item.type && item.type) === 'image/jpeg' || 'image/png' || 'application/pdf'))
      this.ChangeObjectAction({ content, value: __fileList })
    },
    handleRadioSelect (e) {
      const { name, value } = e.target
      this.ChangeFormAction({ content: name, value })
    },
    callback (key) {
      console.log(key)
    },
    onEdit (targetKey, action) {
      this[action](targetKey)
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload jpeg/png/pdf file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('Image must smaller than 20MB!')
      }
      return isJpgOrPng && isLt2M
    },
    // ori
    handleInput (e, content) {
      const { value } = e.target
      // console.log(value, content)
      this.ChangeFormAction({ content, value })
    },
    handleSelect (value, content) {
      this.ChangeFormAction({ content, value })
    },
    handleShowGenerate () {
      this.show_generate_link = true
    },
    ...mapActions('personStepThree', ['ChangeFormAction', 'ChangeObjectAction'])
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.person_third_form.validate(valid => {
      if (valid) {
        next()
      } else {
        next()
      }
    })
  },
  computed: {
    threeForm () {
      return this.$store.state.personStepThree
    },
    getShareLink () {
      return `${window.location.host}/#/signpage/?file_name=Auth_Other_Signture&token=${this.token}`
    },
    get_show_generate_link () {
      return this.show_generate_link === false
    },
    getLastPanesKey () {
      return this.panes[this.panes.length - 1].key
    },
    ...mapState('login', ['token']),
    ...mapState('personStepThree', ['has_authorize_other', 'authorize_title', 'authorize_last_name', 'authorize_first_name', 'authorize_last_name', 'authorize_gender', 'authorize_date_of_birth', 'authorize_place_of_birth', 'authorize_nationality', 'authorize_tax_country', 'relation_with_authorize', 'authorize_residential_address', 'authorize_city', 'authorize_country', 'authorize_postcode', 'authorize_mobile_phone', 'authorize_home_telephone', 'authorize_email_address', 'authorize_id_prove_file_list', 'authorize_address_prove_file_list', 'author_signature', 'rules', 'authorize_nz_driver_front_file_list', 'authorize_nz_driver_back_file_list', 'authorize_nz_bank_statement_ird_file_list', 'authorize_passport_file_list', 'authorize_cn_identify_front_file_list', 'authorize_cn_identify_back_file_list', 'authorize_passport_visa_file_list', 'authorize_nz_bank_card_front_file_list', 'authorize_nz_bank_card_back_file_list']),
    ...mapGetters('countrySelection', ['popularCountryList', 'allCountryList']),
    ...mapGetters('layout', ['GetScreenStyle'])
  }
}
</script>

<style scoped>

.step_container {
  background: rgb(248, 248, 248);
}

.mutiple_form_layout {
  display: flex;
  justify-content: space-between;
}
.step_sub_title {
  margin: 20px 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.ul_main_title {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 15px;
  margin-bottom: 10px;
}
.step_ul_style {
  list-style-type: inside;
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 300;
}
.step_ul_style li {
  line-height: 30px;
}
.step_notice_text {
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65);
}
.marginT_10 {
  margin-top: 15px;
}
.marginX20{
  margin: 20px 0;
}
.claim_text{
  font-weight: 300;
  font-size:17px;
  line-height: 28px;
}
.general_sign_container{
  margin-top: 10px;
  background: #f8f8f8;
  padding:10px;
}
.generate_btn{
  border: 1px solid #ea7840;
  color: #ea7840;
  padding: 10px;
  font-size: 15px;
  font-weight: 300;
  background-color: #fff;
  outline: none;
  margin-top: 5px;
  transition: .2s;
}
.generate_btn:hover{
  background-color:#ea7840;
  color: #fff;
}
.general_sign_title{
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.general_sign_url{
  font-weight: 300;
  margin-top:5px;
  word-break: break-all;
}
.copy_link_btn{
  background-color: #ea7840;
  color: #fff;
  padding: 3px;
  font-size: 14px;
  transition: .2s;
  outline: none;
  display: block;
  margin: 5px 0;
}
.copy_link_btn:hover{
  background: #f09f74;
}
.copy_success_text{
  font-weight: 300;
  color: red;
  font-size: 13px;
}
.modify_antd_radio >>> .ant-radio-inner{
  width: 26px;
  height: 26px;
}
.modify_antd_radio >>> .ant-radio-checked::after{
  height: 25px;
}
.modify_antd_radio >>> .ant-radio-wrapper{
  white-space: pre-wrap;
  display: flex;
}
.modify_antd_radio >>> .ant-radio-inner::after{
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}
.ant-form >>> label{
  font-size: 15px;
}
@media only screen and (max-width: 768px) {
  .mutiple_form_layout{
    display: flex;
    flex-direction: column;
  justify-content: space-between;
  }
}
</style>
